import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

// The service worker is being disabled because it caches the app
// and prevents showing the newest version immediatly.
// See: https://github.com/facebook/create-react-app/issues/1910#issuecomment-355245212

// import registerServiceWorker from './registerServiceWorker'
import { unregister } from './registerServiceWorker'
unregister()

const rootEl = document.getElementById('root')

ReactDOM.render(<App />, rootEl)

if (module.hot) {
  module.hot.accept('./App', () => {
    ReactDOM.render(<App />, rootEl)
  })
}

// registerServiceWorker()
