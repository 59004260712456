import debounce from 'lodash/debounce' // lodash is an antd dependency

export const isFunction = func => typeof func === 'function'

// Adapted from https://stackoverflow.com/a/37312154/417652
export const debounceEventHandler = (func, delay = 500) => {
  const debounced = debounce(func, delay)

  return e => {
    if (isFunction(e.persist)) e.persist()
    return debounced(e)
  }
}

export { debounce }
