import { decorate, observable } from 'mobx'

class Log {
  // Observables
  id = null
  enabled = true
  deleted = false
  createdAt = new Date()
  updatedAt = new Date()

  type = 'NOT-SET'
  data = {}

  constructor(args) {
    Object.assign(this, args)
  }
}

decorate(Log, {
  id: observable,
  enabled: observable,
  deleted: observable,
  createdAt: observable,
  updatedAt: observable,

  type: observable,
  data: observable
})

export default Log
