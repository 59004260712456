import { useEffect } from 'react'
import PropTypes from 'prop-types'

import breakpoints from 'config/breakpoints'
import { debounce } from 'utils/functions'
import { kebabCase } from 'utils/strings'

function breakpointToMediaQuery(breakpoint) {
  return Object.keys(breakpoint)
    .map(feature => `(${kebabCase(feature)}: ${breakpoint[feature]})`)
    .join(' and ')
}

function doesBreakpointMatchMediaQuery(breakpoint) {
  const query = breakpointToMediaQuery(breakpoint)
  return window.matchMedia(query).matches
}

function getBreakpointMatches() {
  return Object.keys(breakpoints)
    .map(bpKey =>
      doesBreakpointMatchMediaQuery(breakpoints[bpKey]) ? bpKey : null
    )
    .filter(breakpoint => Boolean(breakpoint))
}

function Responsive({ children, onChange }) {
  useEffect(() => {
    function runCallback() {
      onChange(getBreakpointMatches())
    }

    runCallback()

    const runDebouncedCallback = debounce(runCallback, 300)

    window.addEventListener('resize', runDebouncedCallback)

    return () => window.removeEventListener('resize', runDebouncedCallback)
  }, [onChange])

  return children
}

Responsive.propTypes = {
  children: PropTypes.any,
  onChange: PropTypes.func,
}
Responsive.defaultProps = {
  onChange: matches => matches,
}

export default Responsive
