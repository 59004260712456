import React, { lazy, Suspense } from 'react'

import { BrowserRouter } from 'react-router-dom'

import Loading from 'ui/Loading'
import PublicRouter from 'routes/PublicRouter'

import styles from './LoginLayout.module.scss'

const Logo = lazy(() => import('ui/Logo'))

function LoginLayout() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Logo />
          </div>
          <div className={styles.content}>
            <PublicRouter />
          </div>
        </div>
      </Suspense>
    </BrowserRouter>
  )
}

export default LoginLayout
