// NOTE: these breakpoints matches Ant Design one (https://ant.design/components/grid/#Col)

export default {
  xs: {
    maxWidth: '575px',
  },
  sm: {
    minWidth: '576px',
    maxWidth: '767px',
  },
  'sm+': {
    minWidth: '576px',
  },
  md: {
    minWidth: '768px',
    maxWidth: '991px',
  },
  'md+': {
    minWidth: '768px',
  },
  lg: {
    minWidth: '992px',
    maxWidth: '1199px',
  },
  'lg+': {
    minWidth: '992px',
  },
  xl: {
    minWidth: '1200px',
    maxWidth: '1599px',
  },
  'xl+': {
    minWidth: '1200px',
  },
  xxl: {
    minWidth: '1600px',
  },
}
