import React from 'react'

import LabelIcon from 'ui/LabelIcon'

import styles from './Loading.module.scss'

function Loading() {
  return (
    <div className={styles.wrap}>
      <LabelIcon label="Loading" icon="spinner" spin />
    </div>
  )
}

export default Loading
